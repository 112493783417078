import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"
import PDF from "../pdfs/Five-ways-that-ESG-creates-value.pdf"
import PDF2 from "../pdfs/li-green-economy-report-2022.pdf"
import PDF3 from "../pdfs/SEBiBODMeeting.pdf"
import BRSRF from "../pdfs/BRSRFormat.pdf"
import SEBI1 from "../pdfs/SEBI1.pdf"
import SEBI2 from "../pdfs/SEBI2.pdf"
import SEBI3 from "../pdfs/SEBI3.pdf"
import BRSRFormat from "../pdfs/GuidanceNote-BRSRByListedEntities.pdf"
import SecuritiesEcchange from "../images/SEBI-logo.png"
import BRST from "../pdfs/BusinessResponsibilityandSustainabilityReport.pdf"
import eyBuilding from "../images/eyBuilding.png"
import INDIACSR from "../images/INDIACSR-LOGO.png"
import kpmg from "../images/kpmg.png"
import linkedEconomicGraph from "../images/linkedEconomicGraph.jpeg"
import mckinsey from "../images/mckinsey.png"
import undp from "../images/newDesign/ourPublication/undp.png"
// import Logo from "../images/IndianESGNetwork.png"
import Logo from "../images/newDesign/logo.svg"
import blank from "../images/newDesign/ourPublication/blank.jpg"
import ChangingLandscapeofESG from "../pdfs/ChangingLandscapeofESG.pdf"

import ourPublication from "../images/newDesign/ourPublication/book-library.jpg"
import Carousel from "react-multi-carousel"
import LifeCycleAssessment from "../pdfs/LifeCycleAssessment.pdf"
import SustainabilityGap from "../pdfs/Circular-Economy-Blog.pdf"
import UnderstandingCarbon from "../pdfs/CarbonAccountingBlog.pdf"
import Social from "../pdfs/Social.pdf"

export default function Knowledge_repository() {
  const columnSection = [
    { heading: "OUR PUBLICATIONS" },
    {
      heading: "REGULATORY SECTION",
    },
    {
      heading: "OTHER RESERCH REPORTS",
    },
  ]

  const regulatorySection = [
    {
      heading: "Securities and Exchange Board of India",
      href: PDF3,
      src: SecuritiesEcchange,
    },
    {
      heading: "Business Responsibility & Sustainability Reporting Format",
      text: "",
      href: BRSRF,
      src: blank,
    },
    {
      heading:
        "Guidance Note For Business Responsibility & Sustainability Reporting Format",
      text: "",
      href: BRSRFormat,
      src: blank,
    },
    {
      heading:
        "BRSR Core - Framework for assurance and ESG disclosures for value chain",
      text: "",
      href: SEBI1,
      src: blank,
    },
    {
      heading:
        "Balanced Framework for ESG Disclosures, Ratings and Investing",
      text: "",
      href: SEBI2,
      src: blank,
    },
    {
      heading:
        "Master circular for ESG",
      text: "",
      href: SEBI3,
      src: blank,
    },
  ]
  const ourPublications = [
    {
      heading:"Social Aspect of ESG",
      text: "The social aspect of ESG is a critical dimension that reflects a company's commitment to ethical conduct, stakeholder well-being, and broader societal impact.",
      href: Social
    },
    {
      heading:"Understanding Carbon Accounting: Tracking Emissions for a Sustainable Future",
      href: UnderstandingCarbon
    },
    {
      heading:"Closing the Sustainability Gap: A Deep Dive into Circular Economy Practices.",
      href:SustainabilityGap
    },
    {
      heading:"A Comprehensive Guide to Life Cycle Assessment (LCA) and its Role in ESG Implementation",
      href: LifeCycleAssessment
    },
    {
      heading:
        "Committee of Sponsoring Organizations of the Treadway Commission",
      text: "As per the SBEI guidelines, sustainability reporting has become mandatory for the top 1000 listed companies and the top 150 companies in the BRSR Core for the fiscal year 2024-2025.",
      linkType: "internal",
      href: "/knowledge_repository/coso-icsr",
      src: Logo,
    },
    {
      heading: "Changing Landscape of ESG in India",
      text: "Today, Climate change discussions are a central focus for governments, environmentalists, regulatory bodies, scientists across the globe as climate change is a global emergency that goes beyond national borders.",
      // linkType: "internal",
      href: ChangingLandscapeofESG,
      src: Logo,
    },
    {
      heading:
        "Building a Sustainable Future: The Role of Green Jobs, ESG and Industry 4.0 in the Indian Green Economy",
      text: "The Environment, Social, and Governance (ESG) Principles are a framework of principles used to assess a company's sustainability and social impact. ESG is becoming increasingly crucial for businesses as concerns about climate change and social responsibility emerge.",
      linkType: "internal",
      href: "/knowledge_repository/building-a-sustainable-future",
      src: Logo,
    },
  
  ]
  const otherReseacrhReport = [
    {
      heading: "The Climate Dictionary",
      text: "The United Nations Development Programme (UNDP) releasing a climate dictionary is a valuable resource for enhancing understanding and communication about climate-related issues.",
      linkType: "internal",
      href: "/knowledge_repository/undp_climate_change",
      src: undp,
      width: "17%",
    },
    {
      heading: "Five ways that ESG creates value",
      text: "",
      href: PDF,
      src: mckinsey,
    },
    {
      heading: "Global Green Skills Report 2022",
      text: "",
      href: PDF2,
      src: linkedEconomicGraph,
    },
    {
      heading: "ESG Prioritization and Measures Taken by Companies in India",
      text: "",
      href: "https://indiacsr.in/esg-prioritization-and-measures-taken-by-companies-in-india/",
      src: INDIACSR,
    },
    {
      heading: "Upskilling for the green economy",
      text: "",
      href: "https://kpmg.com/xx/en/home/insights/2022/08/upskilling-for-the-green-economy.html",
      src: kpmg,
    },
    {
      heading: "Realizing the potential of a US$26 trillion economy",
      text: "",
      href: "https://www.ey.com/en_in/india-at-100",
      src: eyBuilding,
    },
  ]

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <>
      <div className="container-custom">
        <div className="mt-5 pt-5 px-5">
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mb-4">
              <h1 className="heading mb-3">OUR PUBLICATIONS</h1>

              {ourPublications.map(data => {
                return (
                  <>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <ul>
                          <li>
                            <h4 className="card-heading">{data.heading}</h4>
                            <div className="comman-para">{data.text}</div>
                            {data.linkType === "internal" ? (
                              <Link className=" mt-3" to={data.href}>
                                Click here to view more
                              </Link>
                            ) : (
                              <a
                                className="mt-3"
                                href={data.href}
                                target="_blank"
                              >
                                Click here to view more
                              </a>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 ">
              <img
                src={ourPublication}
                // style={{ width: "75%" }}
                className="img-fluid"
                alt={``}
              />
            </div> */}
          </div>
        </div>
        <div className="mt-5 pt-5 px-5">
          <h1 className="heading">REGULATORY SECTION</h1>
          <div className="row">
            {regulatorySection.map(data => {
              return (
                <div className="col-md-4 mt-3">
                  <Card className="h-100 custom-card border">
                    <Card.Body className="card-repository">
                      <div className="row">
                        <div className="col-md-12">
                          <h4 className="card-heading">{data.heading}</h4>
                          {data.text}
                        </div>
                      </div>
                      <div className="row align-items-center mt-4">
                        <div className="col-7">
                          <div>
                            {data.linkType === "internal" ? (
                              <Link to={data.href}>
                                Click here to view more
                              </Link>
                            ) : (
                              <a href={data.href} target="_blank">
                                Click here to view more
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="col-5">
                          <img src={data.src} className="img-fluid" alt={``} />
                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="col-md-8"></div>
                        <div className="col-md-4 text-end"></div>
                      </div> */}
                    </Card.Body>
                  </Card>
                </div>
              )
            })}
          </div>
          <div className="pt-5 mt-5">
            <h1 className="heading">OTHER REPORTS</h1>
            <div className="row">
              {otherReseacrhReport.map(data => {
                return (
                  <div className="col-md-4 mt-3">
                    <Card className="h-100 custom-card border">
                      <Card.Body className="card-repository">
                        <div className="row">
                          <div className="col-md-12 order-2 order-md-1 order-lg-1">
                            <h4 className="card-heading">{data.heading}</h4>
                            {data.text}
                          </div>
                        </div>

                        <div className="row align-items-center mt-4">
                          <div className="col-7">
                            <div>
                              {data.linkType === "internal" ? (
                                <Link to={data.href}>
                                  Click here to view more
                                </Link>
                              ) : (
                                <a href={data.href} target="_blank">
                                  Click here to view more
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="col-5">
                            <img
                              style={{ width: data?.width }}
                              src={data.src}
                              className="img-fluid"
                              alt={``}
                            />
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
